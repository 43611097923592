import '/src/scripts/_global';

import '/src/components/next/globals/navbar/navbar';

import '/src/components/next/blocks/logos/logos';
import '/src/components/next/blocks/posts/posts';
import '/src/components/next/blocks/feedback/feedback';
import '/src/components/next/blocks/contactus/contactus';

document.querySelector('.js-go-back')?.addEventListener('click', () => {
  history.back();
});
