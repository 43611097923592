import { addEventListeners, loop } from '/src/scripts/utils/common';
import { updateScrollOut } from '/src/scripts/es-modules/scroll-reveal';

addEventListeners('.js-show-more-posts', 'click', (e, button) => {
  const items = button
    .closest('.section')
    .querySelectorAll('.posts__item_more');

  loop(items, (item) => {
    item.classList.remove('posts__item_more');
  });

  button.remove();

  updateScrollOut();
});
